// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';
import ProductsListTile from './ProductsListTile';
import './Dashboard.css';
import RequestsListTile from './RequestsListTile';
import styled from 'styled-components';
import ReceiptsTile from './ReceiptsTile';
import OverviewTile from './OverviewTile';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Bar } from 'react-chartjs-2';
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Pie } from 'react-chartjs-2';
// ChartJS.register(ArcElement, Tooltip, Legend);

// import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, LineElement, PointElement, Title} from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import { getRequest } from '../util/request';
import { CONDITION_AGGS_URL, ORDERS_STATS_URL, PRODUCTS_URL, SELLER_AGGREGATION_URL } from '../common/urls';
import AuthService from '../common/AuthService';
ChartJS.register(
  ArcElement, 
  Tooltip, 
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
);
// ChartJS.register(
  // Tooltip,
  // Legend
// );

const conditionColors = {
  'neu': '#A6F4C5',
  'neuwertig': '#56805B',
  'sehr gut': '#91AC94',
  'gut': '#A687B4',
  'second hand': '#FEDF89',
  'repaired': '#FDB022',
  'akzeptabel': '#F97066',
  'ok': '#F97066',
  'none': '#D0D5DD'
}

const SellerAggregationItem = styled.div`
  display: table-row;
  margin: 10px 5px;
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // align-items: center;
  &.header{
    font-weight: bold;}
  .seller-cell{
    display: table-cell;
    padding: 5px;
  }
`

const SellersOverview = styled.div`
  display: table;
`

const SellerAggregationTile = styled.div`

`

const Dashboard = (props) => {
  const [conditionAggregation, setConditionAggregation] = useState({});
  const [ordersStats, setOrdersStats] = useState({});
  const [totalProducts, setTotalProdcts] = useState(0);
  const [sellerAggregation, setSellerAggregation] = useState([]);
  // const products = props.products;
  const adminViewAs = props.adminViewAs;
  const showAsCustomer = props.showAsCustomer;
  const isAdminUser = props.user && props.user.is_superuser;
  useEffect(() => {
    getRequest(`${CONDITION_AGGS_URL}${showAsCustomer ? `?customerId=${adminViewAs}` : ""}`, (response) => {
      setTotalProdcts(response.total);
      response['none'] = response['null']
      delete response['null'];
      delete response['total'];
      setConditionAggregation(response)
    })
    getRequest(`${ORDERS_STATS_URL}${showAsCustomer ? `?customerId=${adminViewAs}` : ""}`, (response) => {setOrdersStats(response)})
    if (isAdminUser){
      getRequest(`${SELLER_AGGREGATION_URL}`, (response) => {setSellerAggregation(response)})
    }
  }, [props]);
  var productConditionData = {
    labels: Object.keys(conditionAggregation).map((condition) => condition.charAt(0).toUpperCase() + condition.slice(1)),
    datasets:[
      {
        label: 'Condition',
        borderColor: '#405d43',
        borderWidth: 1,
        backgroundColor: Object.keys(conditionAggregation).map((condition) => conditionColors[condition.toLowerCase()]),
        legend: {display:false},
        data: Object.values(conditionAggregation).map((conditionCount)=>{return ((conditionCount/totalProducts)*100).toFixed(1)})
      }
    ]
  }
  var salesData = {
    labels: ordersStats.sales ? ordersStats.sales.map((saleAggregateObject) => {return new Date(saleAggregateObject.month).toLocaleString('default', { month: 'short' })}): [],
    datasets:[
      {
        label: 'Number of Orders',
        borderColor: '#405d43',
        borderWidth: 2,
        legend: {display:false},
        data: ordersStats.sales ? ordersStats.sales.map((saleAggregateObject) => {return saleAggregateObject.count}): [],
      }
    ]
  }
  var i = 0;
  const lineOptions = {
    indexAxis: 'x',
    elements: {
      responsive: true,
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
    },
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.formattedValue || '';
            label += '%';
            return label;
          }
        }
      }
    },
  };
  return (
    <div>
      <h2>Overview</h2>
      <div className="dashboard-page">
        <OverviewTile adminViewAs={adminViewAs} showAsCustomer={showAsCustomer} ordersStats={ordersStats}/>
        <ReceiptsTile adminViewAs={adminViewAs} showAsCustomer={showAsCustomer}/>
        <div className="dashboard-tile">
          <h3>Products Condition</h3>
          <div style={{ width: '400px', margin: '0 auto' }}>
            <Doughnut options={options} data={productConditionData} />
          </div>
        </div>
        {/* <div className="dashboard-tile">
          <h3>Sales</h3>
          <div style={{ width: '300px', margin: '0 auto'}}>
            <Line options={lineOptions} data={salesData} />
          </div>
          <div style={{ width: '300px', margin: '0 auto'}}>
            <Line options={lineOptions} data={salesData} />
          </div>
        </div> */}
        <ProductsListTile adminViewAs={adminViewAs} showAsCustomer={showAsCustomer}/>
        <RequestsListTile adminViewAs={adminViewAs} showAsCustomer={showAsCustomer}/>
        {
          isAdminUser && 
          <div className="dashboard-tile">
            <h3>Sellers</h3>
            <SellersOverview>
              <SellerAggregationItem className="header">
                    <span className="seller-cell">Seller</span><span className="seller-cell">Products</span><span className="seller-cell">Resale</span>
              </SellerAggregationItem>
            {
              sellerAggregation.sort((sellerDetailObjectA, sellerDetailObjectB) => {return sellerDetailObjectB.resale_value - sellerDetailObjectA.resale_value}).map((sellerDetailObject) => {
                console.log(sellerDetailObject)
                return (
                  <SellerAggregationItem>
                    <span className="seller-cell">{sellerDetailObject['seller_name'] ? sellerDetailObject['seller_name'] : 'Uncategorized'}</span><span className="seller-cell">{sellerDetailObject['total_count']}</span><span className="seller-cell">{sellerDetailObject['resale_value']}€</span>
                  </SellerAggregationItem>
                )
              })
            }
            </SellersOverview>
          </div>
        }
      </div>
    </div>
  );
};

export default Dashboard;
