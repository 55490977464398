// ProductsList.js
import React, { useEffect, useState } from 'react';
// import './ProductsList.css';
import styled from 'styled-components'
import { getRequest } from '../util/request';
import { API_URL, BASE_URL, CONDITION_AGGS_URL, ORDERS_STATS_URL, PRODUCT_STATS_URL, REQUESTS_URL } from '../common/urls';
import LoadingOverlay from '../common/LoadingOverlay';

const OverviewDashboardTile = styled.div`
  width: 300px;
  .overview-dashboard-facts-wrapper{
    margin-top: 30px;
    .overview-dashboard-fact-item{
      margin: 10px 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .overview-dashboard-fact-title{
        font-weight: bold;
      }
    }
  }
`

function OverviewTile(props) {
  // const [productsStockCount, setProductsStockCount] = useState(0)
  // const [totalResaleValue, setTotalResaleValue] = useState(0)
  const [productStats, setProductStats] = useState({})
  // const [ordersStats, setOrdersStats] = useState({})
  const adminViewAs = props.adminViewAs;
  const isShowAsCustomer = props.showAsCustomer;
  const ordersStats = props.ordersStats
  // console.log(productStats);
  useEffect(() => {
    getRequest(`${PRODUCT_STATS_URL}${isShowAsCustomer ? `?customerId=${adminViewAs}` : ""}`, (response) => {setProductStats(response)})
    // getRequest(`${ORDERS_STATS_URL}${isShowAsCustomer ? `?customerId=${adminViewAs}` : ""}`, (response) => {setOrdersStats(response)})
  },[isShowAsCustomer])
  return (
    <OverviewDashboardTile className="dashboard-tile">
      <h3>Overview</h3>
      <div className="overview-dashboard-facts-wrapper">
        {/* <div className="overview-dashboard-fact-item">
          <span className="overview-dashboard-fact-title">Total Orders:</span>
          <span className="overview-dashboard-fact-value">{`${ordersStats.total_orders ? ordersStats.total_orders : 0}`}</span>
        </div> */}
        <div className="overview-dashboard-fact-item">
          <span className="overview-dashboard-fact-title">Total Items Sold:</span>
          <span className="overview-dashboard-fact-value">{`${ordersStats.total_items_sold ? ordersStats.total_items_sold : 0}`}</span>
        </div>
        <div className="overview-dashboard-fact-item">
          <span className="overview-dashboard-fact-title">Sold GMV:</span>
          <span className="overview-dashboard-fact-value">{`${ordersStats.sold_item_total_earning ? ordersStats.sold_item_total_earning.toLocaleString('de-DE') : 0} €`}</span>
        </div>
        <div className="overview-dashboard-fact-item">
          <span className="overview-dashboard-fact-title">Products in Stock:</span>
          <span className="overview-dashboard-fact-value">{`${productStats.total_stock_count ? productStats.total_stock_count : 0}`}</span>
        </div>
        <div className="overview-dashboard-fact-item">
          <span className="overview-dashboard-fact-title">Total Resale Potential:</span>
          <span className="overview-dashboard-fact-value">{`${productStats.total_products_value ? productStats.total_products_value.toLocaleString('de-DE') : 0} €`}</span>
        </div>
        {/* <div className="overview-dashboard-fact-item">
          <span className="overview-dashboard-fact-title">Total Items:</span>
          <span className="overview-dashboard-fact-value">{`${productStats.products_count ? productStats.products_count : 0}`}</span>
        </div> */}
      </div>
    </OverviewDashboardTile>
  );
}

export default OverviewTile;
